<template>
    <div>
        <div style="margin-bottom: 15px;">
            <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Events style settings</label>
            <div class="control" style="display:flex;align-items:center;">
                <label class="label" style="width:250px;margin-bottom:0;display:block;"
                >Turn on page</label
                >
                <v-switch hide-details v-model="landing.branding.event_main_page.active"></v-switch>
            </div>
        </div>
        <div v-if="landing.branding.event_main_page.active">

            <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
                <label class="label" style="display:block;font-size:20px;font-weight: bold;">General settings</label>
                <div style="display: flex;flex-direction: column;padding:0 15px;">
                    <v-tabs style="margin-bottom: 15px;"
                            class="langs__tabs"
                            v-model="activeLang"
                    >
                        <v-tab
                                v-for="(selected_lang, i) in selected_langs"
                                :key="i"
                                @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
                        >
                            <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                            {{ selected_lang }}
                        </v-tab>
                        <v-tab-item v-for="(selected_lang, i) in selected_langs" :key="i">
                            <label class="label" style="display: block;margin-bottom: 15px;">Top text</label>
                            <Editor
                                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                                    :init="options"
                                    v-model="landing.branding.event_main_page.title[selected_lang]"
                            />
                        </v-tab-item>
                    </v-tabs>
                    <div style="display: flex;align-items: center;margin-bottom: 15px;">
                        <label class="label" style="margin-bottom: 0;margin-right: 8px;display: block;width: 250px;">Play interval (seconds) </label>
                        <v-text-field
                                class="input input_dense"
                                outlined
                                solo
                                flat
                                type="number"
                                dense
                                placeholder="Write your link"
                                v-model.number="landing.branding.event_main_page.autoplay"
                                hide-details
                                style="max-width:200px;"
                        ></v-text-field>
                    </div>
                </div>
            </div>

            <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
                <label class="label" style="display:block;font-size:20px;font-weight: bold;">Style settings</label>
                <div style="display: flex;flex-direction: column;padding:0 15px;">
                    <div
                            style="margin-top:15px;"
                    >
                        <div style="display: flex;align-items: center;margin-bottom:15px">
                            <label
                                    class="label"
                                    style="width:250px;margin-bottom: 0;"
                            >
                                Background
                            </label>
                            <v-menu
                                    top
                                    nudge-left="16"
                                    nudge-bottom="98"
                                    :close-on-content-click="false"
                                    v-model="show_slider_color"
                            >
                                <template v-slot:activator="{ on }">
                                    <div
                                            class="colorpicker"
                                            :style="`background-color:${landing.branding.event_main_page.background}`"
                                            v-on="on"
                                    />
                                </template>
                                <v-card>
                                    <v-card-text class="pa-0">
                                        <v-color-picker
                                                show-swatches
                                                v-model="landing.branding.event_main_page.background"
                                                mode="rgba"
                                                flat
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </div>
                        <div style="display: flex;align-items: center;margin-bottom:15px">
                            <label
                                    class="label"
                                    style="width:250px;margin-bottom: 0;"
                            >
                                Border color
                            </label>
                            <v-menu
                                    top
                                    nudge-left="16"
                                    nudge-bottom="98"
                                    :close-on-content-click="false"
                                    v-model="show_slider_color_border"
                            >
                                <template v-slot:activator="{ on }">
                                    <div
                                            class="colorpicker"
                                            :style="`background-color:${landing.branding.event_main_page.border_color}`"
                                            v-on="on"
                                    />
                                </template>
                                <v-card>
                                    <v-card-text class="pa-0">
                                        <v-color-picker
                                                v-model="landing.branding.event_main_page.border_color"
                                                flat
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </div>
                        <div style="display:flex;margin-bottom:15px;align-items:center;">
                            <label class="label" style="width:250px;margin-bottom:0">Position</label>
                            <v-radio-group
                                    hide-details
                                    v-model="landing.branding.event_main_page.show_type"
                                    row
                            >
                                <v-radio label="Bottom" value="bottom"></v-radio>
                                <v-radio label="Top" value="top"></v-radio>
                            </v-radio-group>
                        </div>
                        <div style="display:flex;margin-bottom:15px;align-items:center;">
                            <label class="label" style="min-width:250px;margin-bottom:0"
                            >Block  height:
                                <b
                                >{{ landing.branding.event_main_page.vertical.height }}
                                    px</b
                                ></label
                            >
                            <div class="slider" style="width:100%;">
                                <v-slider
                                        hide-details
                                        :min="30"
                                        :max="500"
                                        v-model="landing.branding.event_main_page.vertical.height"
                                ></v-slider>
                            </div>
                        </div>
                        <div style="display:flex;margin-bottom:15px;align-items:center;">
                            <label class="label" style="min-width:250px;margin-bottom:0"
                            >Block width
                                <b
                                >{{ landing.branding.event_main_page.vertical.width }}
                                    px</b
                                ></label
                            >
                            <div class="slider" style="width:100%;">
                                <v-slider
                                        hide-details
                                        :min="30"
                                        :max="500"
                                        v-model="landing.branding.event_main_page.vertical.width"
                                ></v-slider>
                            </div>
                        </div>
                        <div style="display:flex;margin-bottom:15px;align-items:center;">
                            <label class="label" style="min-width:250px;margin-bottom:0"
                            >Image height:
                                <b
                                >{{landing.branding.event_main_page.vertical.img_height }}
                                    px</b
                                ></label
                            >
                            <div class="slider" style="width:100%;">
                                <v-slider
                                        hide-details
                                        :min="30"
                                        :max="500"
                                        v-model="landing.branding.event_main_page.vertical.img_height"
                                ></v-slider>
                            </div>
                        </div>
                        <div style="display:flex;margin-bottom:15px;align-items:center;">
                            <label class="label" style="min-width:250px;margin-bottom:0;margin-right: 8px;">Border radius <b>{{ landing.branding.event_main_page.radius }} px</b></label>
                            <div class="slider" style="width:100%;">
                                <v-slider
                                        hide-details
                                        :min="0"
                                        :max="50"
                                        v-model="landing.branding.event_main_page.radius"
                                ></v-slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
                <label class="label" style="display:block;font-size:20px;font-weight: bold;">View all settings</label>
                <div style="display: flex;flex-direction: column;padding:0 15px;">
                    <v-tabs
                            class="langs__tabs"

                            v-model="activeLang"
                    >
                        <v-tab
                                v-for="(selected_lang, i) in selected_langs"
                                :key="i"
                                @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
                        >
                            <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                            {{ selected_lang }}
                        </v-tab>
                        <v-tab-item v-for="(selected_lang, i) in selected_langs" :key="i">
                            <div style="display: flex;align-items: center;margin-bottom: 15px;">
                                <label class="label" style="margin-bottom: 0;margin-right: 8px;display: block;width: 250px;">Text </label>
                                <v-text-field
                                        class="input input_dense"
                                        outlined
                                        solo
                                        flat
                                        dense
                                        placeholder="Write your text"
                                        v-model="landing.branding.event_main_page.all_events.text[selected_lang]"
                                        hide-details
                                        style="max-width:200px;"
                                ></v-text-field>
                            </div>
                        </v-tab-item>
                    </v-tabs>

                    <div>
                        <div style="display: flex; align-items: center; margin-bottom: 15px">
                            <label class="label" style="display:block;min-width:250px;margin-bottom:0;margin-right: 8px">
                                Font Color
                            </label>
                            <div class="control">
                                <v-menu
                                        top
                                        nudge-left="16"
                                        nudge-bottom="98"
                                        :close-on-content-click="false"
                                        v-model="show_text_color"
                                >
                                    <template v-slot:activator="{ on }">
                                        <div
                                                class="custom__menu--color"
                                                :style="`background-color:${landing.branding.event_main_page.all_events.styles.color}`"
                                                v-on="on"
                                        />
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-color-picker v-model="landing.branding.event_main_page.all_events.styles.color" flat />
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </div>
                        </div>

                        <div style="display:flex;align-items:center;margin-bottom:15px;">
                            <label class="label" style="display:block;min-width:250px;margin-bottom:0;margin-right:8px;">
                                Font Size <b>{{ landing.branding.event_main_page.all_events.styles.fontSize }}px</b>
                            </label>
                            <div class="control" style="width:100%;">
                                <div class="slider">
                                    <v-slider
                                            hide-details
                                            :max="40"
                                            :min="10"
                                            v-model="landing.branding.event_main_page.all_events.styles.fontSize"
                                    ></v-slider>
                                </div>
                            </div>
                        </div>

                        <div style="display:flex;align-items:center;margin-bottom:15px;">
                            <label class="label" style="display:block;min-width:250px;margin-bottom:0;margin-right:8px;">
                                Font Weight <b>{{ parseInt(landing.branding.event_main_page.all_events.styles.fontWeight, 10) / 10 }}%</b>
                            </label>
                            <div class="control" style="width:100%;">
                                <div class="slider">
                                    <v-slider
                                            hide-details
                                            :max="1000"
                                            :min="100"
                                            v-model="landing.branding.event_main_page.all_events.styles.fontWeight"
                                            :step="100"
                                    ></v-slider>
                                </div>
                            </div>
                        </div>

                        <div style="display: flex; justify-content: space-between;margin-bottom:15px;">
                            <div style="display:flex;align-items:center;">
                                <label class="label" style="display:block;min-width:250px;margin-bottom:0;margin-right:8px">
                                    Font Style <b>{{ landing.branding.event_main_page.all_events.styles.fontStyle }}</b>
                                </label>
                                <div class="control">
                                    <v-radio-group row hide-details v-model="landing.branding.event_main_page.all_events.styles.fontStyle">
                                        <v-radio
                                                v-for="(type, typeIndex) in fontStyleTypes"
                                                :key="type.id"
                                                :label="type.label"
                                                :value="type.value"
                                                :style="`margin-left:${typeIndex ? '15px' : 0}`"
                                        >
                                        </v-radio>
                                    </v-radio-group>
                                </div>
                            </div>
                            <div style="display:flex;align-items:center;">
                                <label class="label" style="display:block;min-width:250px;margin-bottom:0;"> Font Family </label>

                                <v-select
                                        flat
                                        dense
                                        outlined
                                        hide-details
                                        placeholder="Select Font Family"
                                        :items="fontFamilyTypes"
                                        item-text="label"
                                        item-value="value"
                                        class="canvas__select"
                                        v-model="landing.branding.event_main_page.all_events.styles.fontFamily"
                                        style="max-width: 250px"
                                >
                                    <template v-slot:selection="{ item }">
                                        <div :style="`font-family:'${item.value}',sans-serif`">
                                            {{ item.label }}
                                        </div>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <div :style="`font-family:'${item.value}',sans-serif`">
                                            {{ item.label }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

  import Cropper from "cropperjs";
  import "../../../../node_modules/cropperjs/dist/cropper.min.css";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import {mapGetters, mapMutations, mapState} from "vuex";
  import Compressor from "compressorjs";
  export default {
    name: "Events",
    data() {
      return {
        show_text_color:false,
        fontStyleTypes: [
          { id: 1, value: "normal", label: "Normal" },
          { id: 0, value: "italic", label: "Italic" },
        ],
        fontFamilyTypes: [
          { id: 0, value: "Arial", label: "Arial" },
          { id: 1, value: "SF UI Text", label: "SF UI Text" },
          { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
          { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
          { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
          { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
          { id: 6, value: "Geometria", label: "Geometria" },
          { id: 7, value: "DIN Pro", label: "DIN Pro" },
          { id: 8, value: "Circe", label: "Circe" },
          { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
          { id: 10, value: "Acrom", label: "Acrom" },
          { id: 11, value: "Inter", label: "Inter" },
        ],
        options: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

          mobile: {
            images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
            images_file_types: 'jpg,svg,webp,gif,png',
            images_upload_handler: async  (blobInfo, success, failure) => {
              let formData = new FormData();
              let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
              formData.append("lending_settings_id", this.$store.state.landing.settings_id);
              formData.append("image", _file);
              formData.append("file", _file);
              const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
              success(data);
            },
            plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
          },
          menu: {
            tc: {
              title: "Comments",
              items: "addcomment showcomments deleteallconversations",
            },
          },
          menubar: "file edit view insert format tools table tc help",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
          autosave_ask_before_unload: true,min_height:200,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          importcss_append: true,
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time...",
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          height: 400,
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          content_style: ".mymention{ color: gray; }",
          contextmenu: "link image imagetools table configurepermanentpen",
          skin: "oxide",

          setup:function(editor){
            editor.ui.registry.addButton('custom_button', {
              text: 'Add Button',
              onAction: function() {
                // Open a Dialog
                editor.windowManager.open({
                  title: 'Add custom button',
                  body: {
                    type: 'panel',
                    items: [{
                      type: 'input',
                      name: 'button_label',
                      label: 'Button label',
                      flex: true
                    },{
                      type: 'input',
                      name: 'button_href',
                      label: 'Button href',
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_target',
                      label: 'Target',
                      items: [
                        {text: 'None', value: ''},
                        {text: 'New window', value: '_blank'},
                        {text: 'Self', value: '_self'},
                        {text: 'Parent', value: '_parent'}
                      ],
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_rel',
                      label: 'Rel',
                      items: [
                        {text: 'No value', value: ''},
                        {text: 'Alternate', value: 'alternate'},
                        {text: 'Help', value: 'help'},
                        {text: 'Manifest', value: 'manifest'},
                        {text: 'No follow', value: 'nofollow'},
                        {text: 'No opener', value: 'noopener'},
                        {text: 'No referrer', value: 'noreferrer'},
                        {text: 'Opener', value: 'opener'}
                      ],
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_style',
                      label: 'Style',
                      items: [
                        {text: 'Success', value: 'success'},
                        {text: 'Info', value: 'info'},
                        {text: 'Warning', value: 'warning'},
                        {text: 'Error', value: 'error'}
                      ],
                      flex: true
                    }]
                  },
                  onSubmit: function (api) {

                    var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                    // insert markup
                    editor.insertContent(html);

                    // close the dialog
                    api.close();
                  },
                  buttons: [
                    {
                      text: 'Close',
                      type: 'cancel',
                      onclick: 'close'
                    },
                    {
                      text: 'Insert',
                      type: 'submit',
                      primary: true,
                      enabled: false
                    }
                  ]
                });
              }
            });
          },
          content_css: ["/custom_tinymce.css"],
        },
        links: [],
        deleteIndex: 0,
        show_slider_bg: false,
        default_slider_group: {
          id: 0,
          title: "Example title",
          slider_view_all: {
            show: false,
            text: "View all",
            link: "",
          },
          hide: false,
          settings:false,
          show_type: "bottom",
          border_color: "#cccccc",
          type: "horizontal",
          bg_group:"#ffffff",
          vertical: {
            height: "220",
            width: "150",
            img_height: "150",
          },
          horizontal: {
            height: "150",
            width: "250",
            img_width: "100",
          },
          background: "#ffffff",
          slides: [
            {
              img: require("@/assets/none.png"),
              text: "Example first block",
              text_link: null,
              useLink: false,
              link: null,
            },
          ],
        },
        slider: "",
        show_slider_color: false,
        show_slider_color_border: false,
        dropzoneOptions: {
          thumbnailMethod: "contain",
          acceptedFiles: "image/*",
          addRemoveLinks: true,
          maxFiles: 2,
          maxFilesize: 3,
          dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
          destroyDropzone: false,
          autoProcessQueue:false,
          url: () => {
            return process.env.VUE_APP_API_URL + "upload-image";
          },
          thumbnail: (file, dataUrl) => {
            if (file.cropped) {
              return;
            }else{
              this.compress(file);
            }
          },
        },
        sliderIndexTab: 0,
        sliderGroupIndex: 0,
        defaultSlide:
          {
            img: require("@/assets/none.png"),
            text: "Example block",
            text_link: null,
            useLink: false,
            link: null,
          },
      };
    },
    computed: {
      ...mapState(["selected_langs"]),
      ...mapState(["landing"]),
      ...mapState('branding',['sliderBranding']),
      ...mapGetters("landing", ["current_lang"]),
      branding() {
        return JSON.parse(JSON.stringify(this.landing.branding));
      },
      activeLang:{
        set:function(val){

        },
        get:function(){
          return this.landing.advanced.selected_langs.indexOf(this.landing.current_lang)
        }
      },
    },
    methods: {
      ...mapMutations('branding',['setSlider']),
      ...mapMutations('landing',['setLandingSlider']),
      openEditor(file){
        let editor = document.createElement("div");
        editor.style.position = "fixed";
        editor.style.left = 0;
        editor.style.right = 0;
        editor.style.top = 0;
        editor.style.bottom = 0;
        editor.style.width = "66%";
        editor.style.zIndex = 9999;
        editor.style.backgroundColor = "#000";
        document.body.appendChild(editor);
        let buttonConfirm = document.createElement("button");
        buttonConfirm.style.position = "absolute";
        buttonConfirm.style.right = "10px";
        buttonConfirm.style.top = "10px";
        buttonConfirm.style.zIndex = 9999;
        buttonConfirm.textContent = "Save image";
        buttonConfirm.className = "v-btn v-size--small confirm-control";
        editor.appendChild(buttonConfirm);
        let buttonCancel = document.createElement("button");
        buttonCancel.style.position = "absolute";
        buttonCancel.style.right = "135px";
        buttonCancel.style.top = "10px";
        buttonCancel.style.zIndex = 9999;
        buttonCancel.textContent = "Cancel";
        buttonCancel.className = "v-btn v-size--small confirm-control";
        editor.appendChild(buttonCancel);
        buttonCancel.addEventListener("click", () => {
          this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ].slides[this.sliderIndexTab].img = "";
          this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone.removeAllFiles();
          document.body.removeChild(editor);
          cropper.destroy();
        });
        let buttonRotateLeft = document.createElement("button");
        buttonRotateLeft.className = "cropper-controls";
        buttonRotateLeft.style.left = "24px";
        buttonRotateLeft.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
        editor.appendChild(buttonRotateLeft);
        let buttonRotateRight = document.createElement("button");
        buttonRotateRight.className = "cropper-controls";
        buttonRotateRight.style.left = "64px";
        buttonRotateRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
        editor.appendChild(buttonRotateRight);
        let buttonCrop = document.createElement("button");
        buttonCrop.className = "cropper-controls active-control";
        buttonCrop.style.left = "104px";
        buttonCrop.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
        editor.appendChild(buttonCrop);
        let buttonZoomPlus = document.createElement("button");
        buttonZoomPlus.className = "cropper-controls";
        buttonZoomPlus.style.left = "144px";
        buttonZoomPlus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
        editor.appendChild(buttonZoomPlus);
        let buttonZoomMinus = document.createElement("button");
        buttonZoomMinus.className = "cropper-controls";
        buttonZoomMinus.style.left = "184px";
        buttonZoomMinus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
        editor.appendChild(buttonZoomMinus);
        let buttonLeftRight = document.createElement("button");
        buttonLeftRight.className = "cropper-controls";
        buttonLeftRight.style.left = "224px";
        buttonLeftRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
        editor.appendChild(buttonLeftRight);
        let buttonUpDown = document.createElement("button");
        buttonUpDown.className = "cropper-controls";
        buttonUpDown.style.left = "264px";
        buttonUpDown.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
        editor.appendChild(buttonUpDown);
        buttonConfirm.addEventListener("click", () => {
          let canvas = cropper.getCroppedCanvas();
          let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
          newFile.cropped = true;
          newFile.name = file.name;
          this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone.removeAllFiles();
          this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone.addFile(newFile);
          this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone.processQueue();
          this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src = canvas.toDataURL(file.type);
          this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
          this.$refs[
            `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
            ][0].dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
          document.body.removeChild(editor);
          cropper.destroy();
        });
        buttonRotateRight.addEventListener("click", () => {
          if (!buttonRotateRight.classList.contains("active-control")) {
            let prevBtn = editor.getElementsByClassName("active-control");
            prevBtn[0].classList.remove("active-control");
            buttonRotateRight.classList.add("active-control");
          }
          cropper.rotate(45);
        });
        buttonRotateLeft.addEventListener("click", () => {
          if (!buttonRotateLeft.classList.contains("active-control")) {
            let prevBtn = editor.getElementsByClassName("active-control");
            prevBtn[0].classList.remove("active-control");
            buttonRotateLeft.classList.add("active-control");
          }
          cropper.rotate(-45);
        });

        buttonCrop.addEventListener("click", () => {
          if (!buttonCrop.classList.contains("active-control")) {
            let prevBtn = editor.getElementsByClassName("active-control");
            prevBtn[0].classList.remove("active-control");
            buttonCrop.classList.add("active-control");
          }
          cropper.setDragMode("crop");
        });
        buttonZoomPlus.addEventListener("click", () => {
          if (!buttonZoomPlus.classList.contains("active-control")) {
            let prevBtn = editor.getElementsByClassName("active-control");
            prevBtn[0].classList.remove("active-control");
            buttonZoomPlus.classList.add("active-control");
          }
          cropper.zoom(0.1);
        });
        buttonZoomMinus.addEventListener("click", () => {
          if (!buttonZoomMinus.classList.contains("active-control")) {
            let prevBtn = editor.getElementsByClassName("active-control");
            prevBtn[0].classList.remove("active-control");
            buttonZoomMinus.classList.add("active-control");
          }
          cropper.zoom(-0.1);
        });
        buttonLeftRight.addEventListener("click", () => {
          if (!buttonLeftRight.classList.contains("active-control")) {
            let prevBtn = editor.getElementsByClassName("active-control");
            prevBtn[0].classList.remove("active-control");
            buttonLeftRight.classList.add("active-control");
          }
          if (cropper.getData().scaleX === 1) {
            cropper.scaleX(-1);
          } else {
            cropper.scaleX(1);
          }
        });
        buttonUpDown.addEventListener("click", () => {
          if (!buttonUpDown.classList.contains("active-control")) {
            let prevBtn = editor.getElementsByClassName("active-control");
            prevBtn[0].classList.remove("active-control");
            buttonUpDown.classList.add("active-control");
          }
          if (cropper.getData().scaleY === 1) {
            cropper.scaleY(-1);
          } else {
            cropper.scaleY(1);
          }
        });
        let image = new Image();
        image.src = URL.createObjectURL(file);
        editor.appendChild(image);
        let height,
          width = 0;
        let sliderClass = `.sliderHorizontal_${this.sliderGroupIndex}_${this.sliderIndexTab}`;
        if (
          this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ].type === "horizontal"
        ) {
          height = this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ]["horizontal"].height;
          width = this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ]["horizontal"].width;
        } else {
          height = this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ]["vertical"].height;
          width = this.sliderBranding[this.landing.current_lang][
            this.sliderGroupIndex
            ]["vertical"].width;
          sliderClass = `.sliderVertical_${this.sliderGroupIndex}_${this.sliderIndexTab}`;
        }
        this.sliderBranding[this.landing.current_lang][
          this.sliderGroupIndex
          ].slides[this.sliderIndexTab].img = 1;
        this.changeSlider();
        let cropper = new Cropper(image, {
          preview: sliderClass,
          aspectRatio: height / width,
        });
      },
      async saveImg(file){
        file.cropped = true;
        this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone.removeAllFiles();
        this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone.addFile(file);
        this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone.processQueue();
        this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
        this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
        this.$refs[
          `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
          ][0].dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
      },
      closeCrop(){
        this.$refs[`upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`][0].dropzone.removeAllFiles();
      },
      compress(file){
        new Compressor(file, {
          quality: 0.6,
          mimeType:'image/webp',
          convertSize:0,
          convertTypes:['image/jpeg','image/png','image/jpg'],
          success:(result) => {
            this.$refs.PopupCrop.open(result)
          },
          error(e) {
            console.log(e);
          }
        })
      },
      blobToDataURL(blob) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = _e => resolve(reader.result);
          reader.onerror = _e => reject(reader.error);
          reader.onabort = _e => reject(new Error("Read aborted"));
          reader.readAsDataURL(blob);
        });
      },
      addSlide(slider){
        slider.slides.push(JSON.parse(JSON.stringify(this.defaultSlide)));
      },
      dataURItoBlob(dataURI) {
        let byteString = atob(dataURI.split(",")[1]);
        let mimeString = dataURI
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      },
      deleteSliderGroupConfirm() {
        this.sliderBranding[this.landing.current_lang].splice(
          this.deleteIndex,
          1
        );
        this.changeSlider();
      },
      deleteSliderGroup(ind) {
        this.$refs.PopupDelete.open();
        this.deleteIndex = ind;
      },
      changeSliderIndex(indSlider, indGroup) {
        this.sliderIndexTab = indSlider;
        this.sliderGroupIndex = indGroup;
      },
      show_settings(slider) {
        slider.settings = !slider.settings;
        if (!slider.settings) {
          this.$nextTick(() => {
            for (
              let i = 0;
              i < this.sliderBranding[this.current_lang].length;
              i++
            ) {
              if (this.sliderBranding[this.current_lang][i].slides[0].img) {
                let mockFile = {
                  accepted: true,
                  cropped: true,
                  kind: "image",
                  ...this.sliderBranding[this.current_lang][i].slides[0],
                };
                setTimeout(() => {
                  if (
                    !this.sliderBranding[this.current_lang][i].settings &&
                    !this.sliderBranding[this.current_lang][i].hide
                  ) {
                    if (
                      this.$refs[
                        `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                        ][0] &&
                      this.$refs[
                        `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                        ].dropzone.files.length === 0
                    ) {
                      this.$refs[
                        `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                        ][0].manuallyAddFile(
                        mockFile,
                        this.sliderBranding[this.current_lang][i].slides[0].img
                      );

                      this.$refs[
                        `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                        ][0].dropzone[
                        "previewsContainer"
                        ].children[1].children[0].children[0].src = this.sliderBranding[
                        this.current_lang
                        ][i].slides[0].img;
                      this.$refs[
                        `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                        ][0].dropzone[
                        "previewsContainer"
                        ].children[1].children[0].style.borderRadius = 0;
                      this.$refs[
                        `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                        ][0].dropzone[
                        "previewsContainer"
                        ].children[1].children[1].style.background = "none";
                      this.$refs[
                        `upload_${this.sliderGroupIndex}_${this.sliderIndexTab}`
                        ][0].dropzone.emit("complete", mockFile);
                    }
                  }
                }, 500);
              }
            }
          });
        }
        this.changeSlider();
      },
      hideGroup(slider) {
        slider.hide = true;
        this.changeSlider();
      },
      showGroup(slider) {
        slider.hide = false;
        this.changeSlider();
      },
      addSliderGroup(lang) {
        if(this.sliderBranding[lang].length){
          this.default_slider_group.id =
            +this.sliderBranding[lang][this.sliderBranding[lang].length - 1].id +
            1;
          this.sliderBranding[lang].push(
            JSON.parse(JSON.stringify(this.default_slider_group))
          );
        }else{
          this.default_slider_group.id = 0;
          this.sliderBranding[lang].push(
            JSON.parse(JSON.stringify(this.default_slider_group))
          );
        }
        this.changeSlider();
      },
      async customEvent(_file, xhr, formData) {
        if(_file.cropped){
          xhr.setRequestHeader(
            "Authorization",
            `Bearer ${this.$store.state.token}`
          );
          formData.append("lending_settings_id", this.landing.settings_id);
          formData.append("image", _file);
        }
      },
      onSuccess(file) {
        if(this.sliderBranding[this.current_lang][this.sliderGroupIndex].type === 'horizontal'){
          let sliderClass = `sliderHorizontal_${this.sliderGroupIndex}_${this.sliderIndexTab}`;
          if(document.getElementsByClassName(sliderClass).length){
            document.getElementsByClassName(sliderClass)[0].children[0].src = file.xhr.response;
          }
        }else{
          let sliderClass = `sliderVertical_${this.sliderGroupIndex}_${this.sliderIndexTab}`;
          if(document.getElementsByClassName(sliderClass).length){
            document.getElementsByClassName(sliderClass)[0].children[0].src = file.xhr.response;
          }
        }
        this.sliderBranding[this.current_lang][this.sliderGroupIndex].slides[
          this.sliderIndexTab
          ].img = file.xhr.response;
        this.changeSlider();
      },
      removeFile() {
        this.sliderBranding[this.current_lang][this.sliderGroupIndex].slides[
          this.sliderIndexTab
          ].img = null;
        this.changeSlider();
      },
      setCanvas() {
        this.$store.commit("landing/setBranding", this.branding, true);
      },
      changeLang(lang) {
        this.$store.commit("landing/setCurrentLang", lang);
      },
      deleteSlider(gr, ind) {
        this.sliderBranding[this.landing.current_lang][gr].slides.splice(ind,1);
        this.changeSlider()
      },
    },
    mounted() {
      for (let i = 0; i < this.landing.menu.list.length; i++) {
        let currentMenu = this.landing.menu.list[i];
        let startLink = `${process.env.VUE_APP_LINK}/m/${this.$route.params.id}/`;
        if (currentMenu.type === 1) {
          let link = startLink + "menu/" + currentMenu.page.split(" ").join("");
          this.links.push(link);
        } else if (currentMenu.type === 4) {
          let link = startLink + "menu/" + currentMenu.page.split(" ").join("");
          this.links.push(link);
          for (
            let ind = 0;
            ind <
            currentMenu.content[this.landing.current_lang][3].content.listing
              .length;
            ind++
          ) {
            for (
              let index = 0;
              index <
              currentMenu.content[this.landing.current_lang][3].content.listing[
                ind
                ].content.length;
              index++
            ) {
              let listingLink = `${startLink}menu/${currentMenu.page
                .split(" ")
                .join("")}/${ind + 1}/${index + 1}`;
              this.links.push(listingLink);
            }
          }
        } else if (currentMenu.type === 5) {
          let link =
            startLink + "submenu/" + currentMenu.page.split(" ").join("");
          this.links.push(link);
        } else if (currentMenu.type === 6) {
          let link =
            startLink +
            "submenu/" +
            currentMenu.page.split(" ").join("") +
            "/faq";
          this.links.push(link);
        } else if (currentMenu.type === 7) {
          let link =
            startLink +
            "submenu/" +
            currentMenu.page.split(" ").join("") +
            "/contact";
          this.links.push(link);
        } else if (currentMenu.type === 8) {
          let link =
            startLink +
            "submenu/" +
            currentMenu.page.split(" ").join("") +
            "/events";
          this.links.push(link);
        }
      }
      let canvas = document.getElementsByClassName('canvas');
      if(canvas[0]) {
        canvas[0].scrollTop = canvas[0].scrollHeight;
      }
    },
  };
</script>

<style lang="scss">
    @import "../../../assets/custom_fonts/Geometria/stylesheet.css";
</style>
